
































































































































































@import '@design';

.bubs-mobile-nav-bar {
  a,
  a:visited {
    color: $color-bubs-charcoal;
  }
}

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
    font-weight: bold;
  }
}
